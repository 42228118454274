import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DatabaseService } from "@app/database/database.service";
import { getDefinedMap, getSystemMap } from "@app/projects-v2/util/util";
import { SurveyService } from "@app/survey/survey.service";

interface DataObject {
  [key: string]: number | string | undefined;
}

@Component({
  selector: "app-op-maturity-scoring",
  templateUrl: "./op-maturity-scoring.component.html",
  styleUrls: ["./op-maturity-scoring.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpMaturityScoringComponent implements OnInit {
  step1: boolean = true;
  List: any = ["none"];
  teams: any = [];

  lensRes: any;
  distinctLensMap: any = new Map();
  allLensTable: any[];

  colors: any = [
    "#FFFF",
    "#FAEFFD",
    "#F0F2FF",
    "#FEF8FO",
    "#EFFCF3",
    "#FBF0F0",
  ];
  categoryScores: {};

  teamMap: any = new Map();

  activeTeamIndex: number = 0;

  categories: any = new Map();

  mergedCells: { row: number; col: number; rowspan: number; score: number }[] =
    [];

  handleTeamClick(index: number) {
    this.activeTeamIndex = index;
    this.processTableData(index);
  }

  displayedColumns = [
    "Category",
    "Sub Category",
    "Lens",
    "POI Score",
    "Manager Interview",
    "Engagement Survey",
    "Focus Group",
    "Proposed",
    "PwC Score",
    "Lens Score",
    "Sub Category Score",
    "Category Score",
  ];

  dataSource2: DataObject[] = [];
  dataSource3: DataObject[] = [];

  columnWidths: string[];

  clientId: any;
  data: any;
  spinner: boolean = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private surveyService: SurveyService,
    private databaseService: DatabaseService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.clientId = sessionStorage.getItem("clientId") || "";
    this.opMaturityMapping();
    this.calculateColumnWidths();
  }

  select(event: any) {}

  opMaturityMapData:any;
  public opMaturityMapping(){
    this.databaseService.getOpMaturyMapping(this.clientId).subscribe((res) => {
      // console.log(res)
      if(res.length == 0) {
        this.databaseService.getOpMaturyMapping("default").subscribe((res) => {
          this.opMaturityMapData = res; //DATA
          this.getSurveyStats();
        })
      } else {
        this.opMaturityMapData = res; //DATA
        this.getSurveyStats();
      }
    }, (error) => {
      console.log('error ', error);
  })
  }

  calculateColumnWidths() {
    const numColumns = this.displayedColumns.length;
    const baseWidth = 100 / numColumns;
    const widthArray = [];

    for (let i = 0; i < numColumns; i++) {
      if (i < 3) {
        widthArray.push(`12%`);
      } else {
        widthArray.push(`${baseWidth}%`);
      }
    }

    this.columnWidths = widthArray;
  }

  getSurveyStats() {
    this.teams = [];
    this.teamMap = new Map();
    this.databaseService.getDistinctLens().subscribe(
      (lensRes: any) => {
        this.lensRes = this.opMaturityMapData;

        this.processDistinctLensTable();

        this.surveyService
          .getSurveyOutputs("opMaturity", this.clientId)
          .subscribe(
            (res: any) => {
              // console.log(res,'Fr');
              let res2 = [];
              //Mapping 
              let changedOutputs: any = [];
              if (this.opMaturityMapData.length > 0) {
                for (let template of res) {
                  let changedData: any = [];
                  let temp = JSON.parse(JSON.stringify(template));
                  let modules2: any[] = [];
                  temp.modules = [];
                  for (let data of template.modules) {
                    let module: any = {}
                    module.module = data.module;
                    for (let question of data?.lens) {
                      let changedOutputData: any = { ...question };
                      let modifiedMap = getDefinedMap(this.opMaturityMapData[0],
                        { category: question.category, subCategory: question.subCategory, lens: question.lens })
                      changedOutputData.category = modifiedMap.category
                      changedOutputData.subCategory = modifiedMap.subCategory
                      changedOutputData.lens = modifiedMap.lens

                      changedData.push(changedOutputData);

                    }
                    module.lens = changedData;
                    // console.log(changedData,'cD');
                    modules2.push(module);
                  }
                  temp.modules = modules2;
                 res2.push(temp);
                }
                // console.log(res2);
                //console.log(res,'res-cO');
              }  else {
                res2 = res;
              }
              
              this.data = res2;
              // console.log(this.data , "DD");
              for (let d of this.data) {
                this.teams.push(d.teamName);
                this.teamMap.set(d.teamName, d.clientFunction);
              }
              this.teams.sort();
              this.processTableData(this.activeTeamIndex);
              this.spinner = false;
              this.cdr.markForCheck();
            },
            (error) => {
              // console.log("Error from Output API : ", error);
              let check = " RetryAfterMs";
              this.spinner = false;
              // console.log("error", error);
              if (error.error.message.includes(check)) {
                this.spinner = true;
                this.cdr.markForCheck();
                setTimeout(() => {
                  this.getSurveyStats();
                }, 2000);
              }
              this.cdr.markForCheck();
            }
          );
      },
      (error) => {
        // console.log("Error from Distinct Lens API : ", error);
        this.cdr.markForCheck();
      }
    );
  }

  processDistinctLensTable() {
    this.categories.set("All", "hex");
    for (let r of this.lensRes[0].defined) {
      let key = r.category + "-@-" + r.subCategory + "-@-" + r.lens;
      if (!this.distinctLensMap.has(key)) {
        this.distinctLensMap.set(key, {});
      }
      if (!this.categories.has(r.category)) {
        this.categories.set(r.category, "hex");
      }
    }
  }

  processTableData(index: number) {
    let selectedTeam = this.teams[index];
    // console.log(this.data, "DD2")
    let teamData = this.data.find(
      (x: { teamName: any }) => x.teamName == selectedTeam
    );
    let lensMap = new Map();
    let mi: any = [];
    let poi: any = [];
    let fg: any = [];
    let eva: any = [];
    let pwc: any = [];
    for (let module of teamData.modules) {
      if (module.module == "managerInterviews") {
        mi = module.lens;
      } else if (module.module == "processOwners") {
        poi = module.lens;
      } else if (module.module == "pwc") {
        pwc = module.lens;
      } else if (module.module == "focusGroup") {
        fg = module.lens;
      } else if (module.module == "ava-eva") {
        eva = module.lens;
      } else if (module.module == "opMaturity") {
        pwc = module.lens;
      }
      for (let l of module.lens) {
        let object: any = {};
        object.managerInterview = 0;
        object.poi = 0;
        object.eva = 0;
        object.fg = 0;
        object.pwc = 0;
        let key = l.category + "-@-" + l.subCategory + "-@-" + l.lens;
        lensMap.set(key, object);
      }
    }

    let calculatedData: any = [];

    for (let key of this.distinctLensMap.keys()) {
      let obj: any = {};
      if (lensMap.has(key)) {
        let data = lensMap.get(key);
        let cats = key.split("-@-");
        obj["Category"] = cats[0];
        obj["Sub Category"] = cats[1];
        obj["Lens"] = cats[2];

        let total = 0;
        let count = 0;

        // Process Owner Interviews;

        for (let p of poi) {
          if (
            p.category == cats[0] &&
            p.subCategory == cats[1] &&
            p.lens == cats[2]
          ) {
            obj["POI Score"] = parseFloat(
              (parseFloat(p.answer) / parseInt(p.count)).toFixed(2)
            );
            total = total + parseInt(p.answer);
            count = count + parseInt(p.count);
          }
        }

        if (!obj["POI Score"]) {
          obj["POI Score"] = undefined;
        }

        // Manager Interviews

        for (let p of mi) {
          if (
            p.category == cats[0] &&
            p.subCategory == cats[1] &&
            p.lens == cats[2]
          ) {
            obj["Manager Interview"] = parseFloat(
              (parseFloat(p.answer) / parseInt(p.count)).toFixed(2)
            );
            total = total + parseInt(p.answer);
            count = count + parseInt(p.count);
          }
        }

        if (!obj["Manager Interview"]) {
          obj["Manager Interview"] = undefined;
        }

        // Focus Group

        for (let p of fg) {
          if (
            p.category == cats[0] &&
            p.subCategory == cats[1] &&
            p.lens == cats[2]
          ) {
            if (p.answer > 0) {
              obj["Focus Group"] = parseFloat(
                (parseFloat(p.answer) / parseInt(p.count)).toFixed(2)
              );
              total = total + parseInt(p.answer);
              count = count + parseInt(p.count);
            }
          }
        }

        if (!obj["Focus Group"]) {
          obj["Focus Group"] = undefined;
        }

        // EVA

        for (let p of eva) {
          if (
            p.category == cats[0] &&
            p.subCategory == cats[1] &&
            p.lens == cats[2]
          ) {
            obj["Engagement Survey"] = parseFloat(
              (parseFloat(p.answer) / parseInt(p.count)).toFixed(2)
            );
            total = total + parseInt(p.answer);
            count = count + parseInt(p.count);
          }
        }

        if (!obj["Engagement Survey"]) {
          obj["Engagement Survey"] = undefined;
        }

        // Proposed

        obj["Proposed"] =
          count > 0 ? parseFloat((total / count).toFixed(2)) : undefined;

        for (let p of pwc) {
          if (
            p.category == cats[0] &&
            p.subCategory == cats[1] &&
            p.lens == cats[2]
          ) {
            obj["PwC Score"] = parseFloat(
              (parseFloat(p.answer) / parseInt(p.count)).toFixed(2)
            );
          }
        }

        if (!obj["PwC Score"]) {
          obj["PwC Score"] = undefined;
        }

        // "Lens Score": "4.0",

        obj["Lens Score"] = obj["PwC Score"]
          ? obj["PwC Score"]
          : obj["Proposed"];
      } else {
        let cats = key.split("-@-");
        obj["Category"] = cats[0];
        obj["Sub Category"] = cats[1];
        obj["Lens"] = cats[2];
        obj["POI Score"] = undefined;
        obj["Manager Interview"] = undefined;
        obj["Engagement Survey"] = undefined;
        obj["Focus Group"] = undefined;
        obj["Proposed"] = undefined;
        obj["PwC Score"] = undefined;
        obj["Lens Score"] = undefined;
        obj["Category Score"] = undefined;
        obj["SubCategory Score"] = undefined;
      }
      calculatedData.push(obj);
    }

    // console.log(calculatedData);

    calculatedData.sort(function (
      a: { [x: string]: string },
      b: { [x: string]: any }
    ) {
      return a["Category"].localeCompare(b["Category"]);
    });

    this.dataSource2 = calculatedData;

    // console.log(this.dataSource2);
    this.detectMergedCells();
    this.setCategoryColors();
  }

  setCategoryColors() {
    let i = 0;
    for (let key of this.categories.keys()) {
      this.categories.set(key, this.colors[i]);
      i++;
      if (i > this.colors.length) {
        i = 0;
      }
    }
    // console.log(this.categories);
  }

  validateInput(inputValue: any, row: any) {
    // console.log(inputValue);

    if (!/^[1-5]$/.test(inputValue)) {
      // 1 to 5
      this.snackbar.open("Invalid, Min 1 and Max 5", "close");
    } else {
      this.onEditableColumnEdit(inputValue, row);
    }
  }

  onEditableColumnEdit(value: string, row: any) {
    let postData: any = [];
    for (let cat of this.dataSource2) {
      let obj: any = {};
      let newObj = getSystemMap(this.opMaturityMapData[0],{category: cat["Category"] , subCategory : cat["Sub Category"] , lens : cat["Lens"]})
      obj.category = newObj.category;
      obj.subCategory = newObj.subCategory;
      obj.lens = newObj.lens;
      if (
        row["Category"] == cat["Category"] &&
        row["Sub Category"] == cat["Sub Category"] &&
        row["Lens"] == cat["Lens"]
      ) {
        obj.answer = value;
      } else {
        obj.answer = cat["PwC Score"];
      }
      if (obj.answer) {
        postData.push(obj);
      }
    }
    const payload = {
      clientId: this.clientId,
      teamName: this.teams[this.activeTeamIndex],
      clientFunction: this.teamMap.get(this.teams[this.activeTeamIndex]),
      module: "opMaturity",
      dataResponse: postData,
    };
    this.surveyService.postSurveyDetail(payload).subscribe(
      (res: any) => {
        // console.log("Posted");
        this.getSurveyStats();
      },
      (error: any) => {
        // console.log("Error : " + error);
      }
    );
    // Do something with the edited value or row data
  }

  detectMergedCells() {
    this.mergedCells = [];
    let subCatcount = 0;
    let catCount = 0;
    let catScore = 0;
    let subCatScore = 0;
    let prevCat = this.dataSource2[0]["Category"];
    let prevSubCat = this.dataSource2[0]["Sub Category"];
    let i = 0;
    let catCell = {
      row: 0,
      col: this.displayedColumns.indexOf("Category Score"),
      rowspan: 0,
      score: 0,
    };
    let subCatCell = {
      row: 0,
      col: this.displayedColumns.indexOf("Sub Category Score"),
      rowspan: 0,
      score: 0,
    };

    for (let d of this.dataSource2) {
      let currCat = d["Category"];
      let currSubCat = d["Sub Category"];

      let score: number = 0;
      if (typeof d["Lens Score"] == "number") {
        score = d["Lens Score"] ? d["Lens Score"] : 0;
      } else {
        score = d["Lens Score"] ? parseFloat(d["Lens Score"]) : 0;
      }

      // console.log("score:", score);

      if (currCat == prevCat) {
        catCount = catCount + (score < 1 ? 0 : 1);
        catScore = catScore + (score < 1 ? 0 : score);
        catCell.rowspan = catCell.rowspan + 1;
      } else {
        catCell.score = catScore / catCount;
        if (!isNaN(catCell.score)) {
          this.mergedCells.push({ ...catCell });
        }
        catCell = { row: i, col: catCell.col, rowspan: 1, score: 0 };
        catScore = score;
        catCount = 1;
        prevCat = currCat;
      }

      if (currSubCat == prevSubCat) {
        subCatcount = subCatcount + (score < 1 ? 0 : 1);
        subCatScore = subCatScore + (score < 1 ? 0 : score);
        subCatCell.rowspan = subCatCell.rowspan + 1;
      } else {
        subCatCell.score = subCatScore / subCatcount;
        if (!isNaN(subCatCell.score)) {
          this.mergedCells.push({ ...subCatCell });
        }
        subCatCell = { row: i, col: subCatCell.col, rowspan: 1, score: 0 };
        subCatScore = score;
        subCatcount = 1;
        prevSubCat = currSubCat;
      }

      i = i + 1;
    }

    subCatCell.score = subCatScore / subCatcount;
    catCell.score = catScore / catCount;
    this.mergedCells.push(catCell);
    this.mergedCells.push(subCatCell);

    for (let cell of this.mergedCells) {
      let x = cell.row;
      let y = cell.col;
      if (y === 10) {
        this.dataSource2[x]["Sub Category Score"] =
          cell.score == 0 ? undefined : cell.score.toFixed(2);
      } else if (y === 11) {
        this.dataSource2[x]["Category Score"] =
          cell.score == 0 ? undefined : cell.score.toFixed(2);
      }
    }

    // console.log(this.dataSource2);

    this.dataSource3 = this.dataSource2;
  }

  isMergedCell(row: number, col: number): boolean {
    return this.mergedCells.some(
      (cell) => cell.row === row && cell.col === col
    );
  }

  getRowspan(row: number, col: number): number {
    // console.log(row, col);
    const cell = this.mergedCells.find(
      (cell) => cell.row === row && cell.col === col
    );
    return cell ? cell.rowspan : 1;
  }

  getColor(cat: any) {
    return this.categories.get(cat);
  }

  getValueColor(value: any, header: string) {
    if (
      header == "Category Score" ||
      header == "Sub Category Score" ||
      header == "Lens Score" ||
      header == "PwC Score"
    ) {
      return "white";
    }
    if (value) {
      return "white";
    } else {
      return "#EBEBEB";
    }
  }

  getRowSpan(header: any) {
    if (header == "Category Score" || header == "Sub Category Score") {
      return 1;
    } else {
      return 1;
    }
  }

  onButtonClick(cat: any) {
    let data: any = [];
    if (cat == "All") {
      this.dataSource2 = this.dataSource3;
    } else {
      this.dataSource2 = this.dataSource3;
      for (let d of this.dataSource2) {
        if (d["Category"] == cat) {
          data.push(d);
        }
      }
      this.dataSource2 = data;
    }
  }

  checkForWarning(row: any) {
    if (!row["Proposed"] && !row["PwC Score"]) {
      return true;
    } else {
      return false;
    }
  }
}
